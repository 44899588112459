const fuelTypeOptions = [
  { value: "Petrol", label: "Petrol" },
  { value: "Diesel", label: "Diesel" },
  { value: "Electric", label: "Electric" },
  { value: "Hybrid", label: "Hybrid" },
];

const priceOptions = [
  { value: 0, label: "Any" },
  { value: 25000, label: "25,000" },
  { value: 50000, label: "50,000" },
  { value: 75000, label: "75,000" },
  { value: 100000, label: "100,000" },
  { value: 125000, label: "125,000" },
  { value: 150000, label: "150,000" },
  { value: 175000, label: "175,000" },
  { value: 200000, label: "200,000" },
  { value: 300000, label: "300,000" },
  { value: 400000, label: "400,000" },
  { value: 500000, label: "500,000" },
  { value: 600000, label: "600,000" },
  { value: 700000, label: "700,000" },
  { value: 800000, label: "800,000" },
  { value: 900000, label: "900,000" },
  { value: 1000000, label: "1,000,000" },
];

const emiOptions = [
  { value: 0, label: "Any" },
  { value: 1000, label: "1,000" },
  { value: 1500, label: "1,500" },
  { value: 2000, label: "2,000" },
  { value: 2500, label: "2,500" },
  { value: 3000, label: "3,000" },
  { value: 3500, label: "3,500" },
  { value: 4000, label: "4,000" },
  { value: 5000, label: "5,000" },
  { value: 6000, label: "6,000" },
  { value: 7000, label: "7,000" },
  { value: 8000, label: "8,000" },
  { value: 9000, label: "9,000" },
  { value: 10000, label: "10,000" },
  { value: 11000, label: "11,000" },
  { value: 12000, label: "12,000" },
  { value: 13000, label: "13,000" },
  { value: 14000, label: "14,000" },
  { value: 15000, label: "15,000" },
  { value: 16000, label: "16,000" },
];

const yearOptions = [
  { value: 2000, label: "2000" },
  { value: 2001, label: "2001" },
  { value: 2002, label: "2002" },
  { value: 2003, label: "2003" },
  { value: 2004, label: "2004" },
  { value: 2005, label: "2005" },
  { value: 2006, label: "2006" },
  { value: 2007, label: "2007" },
  { value: 2008, label: "2008" },
  { value: 2009, label: "2009" },
  { value: 2010, label: "2010" },
  { value: 2011, label: "2011" },
  { value: 2012, label: "2012" },
  { value: 2013, label: "2013" },
  { value: 2014, label: "2014" },
  { value: 2015, label: "2015" },
  { value: 2016, label: "2016" },
  { value: 2017, label: "2017" },
  { value: 2018, label: "2018" },
  { value: 2019, label: "2019" },
  { value: 2020, label: "2020" },
  { value: 2021, label: "2021" },
  { value: 2022, label: "2022" },
  { value: 2023, label: "2023" },
  { value: 2024, label: "2024" },
  { value: 2025, label: "2025" },
].reverse();

const engineCapacityOptions = [
  { value: "0.8", label: "0.8 L" },
  { value: "1.0", label: "1.0 L" },
  { value: "1.2", label: "1.2 L" },
  { value: "1.4", label: "1.4 L" },
  { value: "1.5", label: "1.5 L" },
  { value: "1.6", label: "1.6 L" },
  { value: "1.8", label: "1.8 L" },
  { value: "2.0", label: "2.0 L" },
  { value: "2.4", label: "2.4 L" },
  { value: "2.5", label: "2.5 L" },
  { value: "3.0", label: "3.0 L" },
  { value: "3.5", label: "3.5 L" },
  { value: "4.0", label: "4.0 L" },
  { value: "5.0", label: "5.0 L" },
  { value: "5.5", label: "5.5 L" },
  { value: "6.0", label: "6.0 L" },
  { value: "6.2", label: "6.2 L" },
  { value: "6.6", label: "6.6 L" },
  { value: "6.7", label: "6.7 L" },
  { value: "7.3", label: "7.3 L" },
  { value: "8.0", label: "8.0 L" },
];

const vehicleWarrantyYearOptions = [
  { value: "1 Year", label: "1 Year" },
  { value: "2 Years", label: "2 Years" },
  { value: "3 Years", label: "3 Years" },
  { value: "4 Years", label: "4 Years" },
  { value: "5 Years", label: "5 Years" },
  { value: "6 Years", label: "6 Years" },
  { value: "7 Years", label: "7 Years" },
  { value: "8 Years", label: "8 Years" },
  { value: "9 Years", label: "9 Years" },
  { value: "10 Years", label: "10 Years" },
  { value: "11 Years", label: "11 Years" },
  { value: "12 Years", label: "12 Years" },
  { value: "13 Years", label: "13 Years" },
  { value: "14 Years", label: "14 Years" },
  { value: "15 Years", label: "15 Years" },
];

const accordionItems = [
  {
    value: "item-1",
    header: "Title",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    value: "item-2",
    header: "Title",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    value: "item-3",
    header: "Title",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    value: "item-4",
    header: "Title",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    value: "item-5",
    header: "Title",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    value: "item-6",
    header: "Title",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
] as const;

type VehicleSortDropdownOption = "High to Low Price" | "Low to High Price" | "Newest First" | "Oldest First";
const sortOptions: VehicleSortDropdownOption[] = [
  "High to Low Price",
  "Low to High Price",
  "Newest First",
  "Oldest First",
];

const transmissionTypeOptions = [
  { value: "AUTOMATIC", label: "Automatic" },
  { value: "MANUAL", label: "Manual" },
  { value: "BOTH", label: "Both" },
];

export {
  accordionItems,
  fuelTypeOptions,
  priceOptions,
  emiOptions,
  yearOptions,
  sortOptions,
  engineCapacityOptions,
  vehicleWarrantyYearOptions,
  transmissionTypeOptions,
  type VehicleSortDropdownOption,
};
